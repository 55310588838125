import pic1 from "./img/me.jpg";
import pic2 from "./img/me1.jpg"

export const products = [
  {
    id: 1,
    img : pic1,
    link: "https://projects.santosh11.com.np/",
  },
  {
    id: 2,
    img: "https://images.pexels.com/photos/1906658/pexels-photo-1906658.jpeg?cs=srgb&dl=pexels-egil-sj%C3%B8holt-1906658.jpg&fm=jpg",
    link: "https://projects.santosh11.com.np/",
  },
  {
    id: 3,
    img: pic2,
    link: "https://projects.santosh11.com.np/",
  },
  {
    id: 4,
    img: "",
    link: "https://projects.santosh11.com.np/",
  },
  {
    id: 5,
    img: "",
    link: "https://projects.santosh11.com.np/",
  },
  {
    id: 6,
    img: "",
    link: "https://projects.santosh11.com.np/",
  },
];
