import Intro from "./component/intro/Intro";
import Main from "./component/main/Main"
import About from "./component/about/About";
import Toggle from "./component/toggle/Toggle"
//import Product from "./component/productList/ProductList"
import ProductList from "./component/productList/ProductList";
import Contact from "./component/contact/Contact";
import { useContext } from "react";
import { ThemeContext } from "./context";
import {  BrowserRouter, Router,  Routes,  Route} from "react-router-dom";




const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    
    <div
      style={{
        backgroundColor: darkMode ? "#222" : "white",
        color: darkMode && "white",
      }}
    >
     
      <Main />
      <Toggle />
       <Intro />
      
      <About />
      <ProductList />
      <Contact /> 

      
      
        
{/* <Routes>
  
         <Route index element={<Main />} /> 
        <Route path='/intro' exact element={<Intro />} />
        <Route path='/about' element={<About />} />
        
    
</Routes> */}
</div>


    




  )
  


};

export default App;

