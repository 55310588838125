import "./main.css";
import React,{useState} from "react";
import "../../app.js";
import stars from "./images/stars.png";
import moon from "./images/moon.png";
import mountainbehind from "./images/mountains_behind.png";
import mountainfront from "./images/mountains_front.png";
import comet from "./images/comet.png"
import { Link , BrowserRouter} from "react-router-dom";
import Intro from "../intro/Intro";
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";

const Main = () => {
  const [offset, setOffset] = useState()
  const handleScroll = () =>setOffset(window.pageYOffset)
  window.addEventListener('scroll', handleScroll)

  return (
    <div className="container">

      <div className="navbar">
      <div className="menu">
      <a className="logo" href="https://projects.santosh11.com.np/">Demo<span> Projects</span></a>
      {/* <ul className="social">
            <li><a href="https://www.linkedin.com/in/santosh-thapa-449926146/" ><i className="fa-brands fa-linkedin" aria-hidden="true"></i> </a></li>
          <li><a   href="https://www.instagram.com/shauntoshh/"><i className="fa-brands fa-instagram" aria-hidden="true"></i> </a></li>

          </ul> */}
          <div className="hamburger-menu">

            
<div className="bar"></div>

</div>

</div>
      </div>

      <div className="main-container"

      >
        <div className="main">
          <section>
            <img src={stars} alt="" id="stars"
            style={{width: (100 + offset * 0.3) + '%'}}
            />

            <img src={comet} alt="" id="comet"
            style={{width: (15+ offset * 0.09) + '%'}}
            />
            <img src={moon} alt="" id="moon" 
            style={{left: (0 + offset * 0.05) + '%'}}
            />
            <img src={mountainbehind} alt="" id="mountains_behind" 
            //style={{top: `-${0 + offset * 0 + '%'}`}}
            style={{width: (100 + offset * 0.1) + '%'}}

            />
            <h2 id="text"
            style={{width: (60 + offset * 0.15) + '%'}}
            >BIENVENUE TO MY REALM</h2>
          
            <img src={mountainfront} alt="" id="mountains_front"
            style={{Bottom: `-${0+ offset * 0.05 + '%'}`}}

            />
        </section>
        </div>
        {/* <div className="sec" id="sec">
          <h2 className="about">ABOUT ME</h2>
          <h3>"A Web 3.0 and Blockchain Enthusiast"</h3>
          <br/>
          <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure qui, distinctio ab nostrum nam velit nesciunt deserunt perspiciatis sed ut at eaque veniam alias doloremque atque cum doloribus, ducimus hic.
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque necessitatibus tenetur in? Rem facere modi assumenda vitae enim labore blanditiis, autem aspernatur at, voluptas et eveniet beatae vero veniam eos.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident accusantium laborum, aliquam, eveniet atque dolores dolorem cupiditate quos eos at pariatur alias consectetur! Ratione qui, aspernatur harum necessitatibus quidem esse!
              <br/><br/>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure qui, distinctio ab nostrum nam velit nesciunt deserunt perspiciatis sed ut at eaque veniam alias doloremque atque cum doloribus, ducimus hic.
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque necessitatibus tenetur in? Rem facere modi assumenda vitae enim labore blanditiis, autem aspernatur at, voluptas et eveniet beatae vero veniam eos.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident accusantium laborum, aliquam, eveniet atque dolores dolorem cupiditate quos eos at pariatur alias consectetur! Ratione qui, aspernatur harum necessitatibus quidem esse!
              <br/><br/>
              </p>
              </div> */}

        

        </div>
        
        
        
        <div className="links">
        <ul>
        <li>
         <a href="/" style={{"--i":"0.05s"}}>Home</a>
        </li>
        <li>
        <a href="#intro" style={{"--i":"0.10s"}}>Intro</a>
        </li>
        <li>
          <a href="#about" style={{"--i":"0.15s"}}>About</a>
        </li>
        <li>
          <a href="#project" style={{"--i":"0.2s"}}>Projects</a>
        </li>
        <li>
          <a href="#contact" style={{"--i":"0.25s"}}>Contact</a>
        </li>

       
        </ul>
        {/* <Routes>
        <Route path="intro" element={<Intro />} />
        </Routes> */}
      </div>
      
      
       <div className="shadow one"></div>
      <div className="shadow two"></div> 
      
    </div>
        
      
      
      
  );
  
  
};














  export default Main;
